<template>
  <div id="onlineAppointment" style="justify-content: center center" class="pt-0">

    <b-row>

      <b-col cols="12" md="3">
        <v-date-picker class="m-1" :locale="$vs.rtl ? 'ar' : 'en'" @input="getAppointmentSessions(true)"
          v-model="appointmentDate" :attributes="[{
            bar: 'green',
            dates: this.monthSessions.map((ob) => ob.DoctorSession.DateFrom),
          },]" @update:from-page="pageChange" />
      </b-col>
      <b-col cols="12" md="9">

        <vx-card>
          <div id="data-list-list-view" class="data-list-container">
            <vs-table ref="table" :data="Model">
              <template slot="thead">
                <vs-th>{{ $t("Patient") }}</vs-th>
                <vs-th> {{ $t("Date") }}</vs-th>
                <vs-th> {{ $t("Time") }}</vs-th>
                <vs-th> {{ $t("Action") }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <tbody :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-tr class="cursor-pointer hover:text-primary">
                    <vs-td>
                      <div @click="GotoSessionDetails(tr)">

                        <u style="color:#2753D9;text-decoration: underline;"
                          v-if="tr.Patient && (tr.StatusID == 2 || tr.StatusID == 1)">{{
                            tr.Patient.Name
                          }}</u>
                        <p v-else> {{ tr.Patient.Name }}</p>

                      </div>
                    </vs-td>
                    <vs-td>
                      <p class="product-name font-medium truncate" type="date">
                        {{ tr.DoctorSession.DateFormatted }}
                      </p>
                    </vs-td>
                    <vs-td>
                      <p class="product-name font-medium truncate">
                        {{ tr.DoctorSession.DateFrom.slice(11, 16) }}
                      </p>
                    </vs-td>
                    <vs-td>
                      <div v-if="(tr.StatusID == 2 || tr.StatusID == 1)"
                        :disabled="(tr.DoctorSession.DateFormatted > new Date().toISOString().slice(0, 10))">
                        <vx-tooltip :text="$t('Complete')" position="top">
                          <feather-icon @click="CompletePatientReservation(tr)" icon="CheckIcon"></feather-icon>
                        </vx-tooltip>
                      </div>
                      <div v-else>
                        <p class="product-name font-medium truncate" v-if="tr.Status != null">
                          {{ tr.Status.Name }}
                        </p>
                      </div>
                    </vs-td>
                  </vs-tr>
                </tbody>
              </template>
            </vs-table>
          </div>
        </vx-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div class="mt-8 ">
          <b-button @click="$router.go(-1)">{{
            $t("Back")
          }}</b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { domain } from "@/gloabelConstant.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import {
  BButton, BCard, BCol, BFormInput, BInputGroup, BInputGroupAppend, BLink, BRow
} from 'bootstrap-vue';
import moment from "moment";
import Datepicker from "vuejs-datepicker";
export default {
  data() {
    return {
      IsDoctorRequest: true,
      EnglishLan: false,
      baseURL: domain,
      Model: [],
      monthSessions: [],
      date: new Date(),
      appointmentDate: null,
      appointmentDateEnd: new Date(),
      isHidden: true,

      // currentDate:new Date().toISOString().slice(0, 10),
      currentDate: new Date().toLocaleString(),

    };
  },
  computed: {

    attrs() {
      return [
        {
          bar: "green",
          // dates: this.$store.state.DoctorList.monthPatientReservation.map(ob=>ob.DoctorSession.DayUTC),
          dates: this.monthSessions.map((ob) => ob.DoctorSession.DateFrom),
        },
      ];
    },
  },
  components: {
    Datepicker,
    BButton, BCard, BCol, BFormInput, BInputGroup, BInputGroupAppend, BLink, BRow
  },
  methods: {
    checkDate(sesstionDate) {
      debugger;
      const current = new Date();
      sesstionDate = new Date(sesstionDate);
      var diffMs = sesstionDate - current;
      var diffMins = Math.round(diffMs / 60000);

      if (diffMins <= 10)
        return true;

      else return false;
    },
    // CompareDate(DoctorSessionDateFrom)
    // {
    //  DoctorSessionDateFrom = tr.DoctorSession.DateFormatted;
    // },

    pageChange(obj) {
      debugger;
      this.sessions = [];
      debugger;
      //PaymentStatusID: 5
      var search = {
        DateFrom: obj.year + "/" + obj.month + "/1",
        To: new Date(obj.year, obj.month, 0),
        PaymentStatusID: 3
      };
      search.To.setDate(search.To.getDate() + 1)
      search.DateTo = moment(search.To).format("LL");
      this.date = search.DateFrom;
      // this.appointmentDateEnd = this.date;
      //this.getAppointmentSessions(null);
      search.doctorID = this.$route.params.ID;
      this.$store
        .dispatch("DoctorList/getPatientReservation", search)
        .then((res) => {
          debugger
          this.monthSessions = res.data.Data;
          this.Model = res.data.Data;
        });
      // this.getAppointmentSessions(false);
      // this.$store.dispatch("DoctorList/getPatientReservationCalender",search);
    },
    back() {
      this.$router.go(-1);
    },
    GotoSessionDetails(reservation) {
      debugger;
      if (reservation.StatusID == 2 || reservation.StatusID == 1)
        this.$router.push({
          name: "DoctorSessionDetails",
          params: { PatientReservationID: reservation.ID, IsDoctorRequest: this.IsDoctorRequest },
        });
    },
    currentDate(date) {
      debugger;
      const current = new Date();

      if (date > current) {
        debugger;
        return true;
      } else {
        debugger;
        return false;
      }
    },
    addHours(date, h) {
      if (typeof date === "string") date = new Date(date);
      date.setTime(date.getTime() + h * 60 * 60 * 1000);
      return date;
    },
    getAppointmentSessions(isOneDay) {
      // this.appointmentDate = selectedDay;
      debugger;
      var searchAppointment = {};
      //searchAppointment.PaymentStatusID = 5;
      searchAppointment.PaymentStatusID = 3;
      if (this.$route.params.ID > 0) {

        if (isOneDay == true) {
          searchAppointment.DateFrom = moment(this.appointmentDate).format("LL");

        }
        else {
          this.date = new Date(this.date)
          searchAppointment.DateFrom = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
          searchAppointment.DateTo = new Date(this.date.getFullYear(), this.date.getMonth(), 31);

          searchAppointment.DateFrom = moment(searchAppointment.DateFrom).format("LL");
          searchAppointment.DateTo.setDate(searchAppointment.DateTo.getMonth() + 1)
          searchAppointment.DateTo = moment(searchAppointment.DateTo).format("LL");
        }

        searchAppointment.doctorID = this.$route.params.ID;
        //searchAppointment.NotIncludeStatusIds=[1]//pending

        this.$vs.loading();
        this.$store
          .dispatch("DoctorList/getPatientReservation", searchAppointment)
          .then((res) => {
            debugger;
            this.Model = res.data.Data;
            debugger;
            this.$vs.loading.close();
            if ((this.Model == null || this.Model.length == 0) && this.appointmentDate != null) {
              this.$vs.notify({
                title: this.$t("NoData"),
                text: this.$t("NoDataToshow"),
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "warning",
              });
            }
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      }
    },
    CompletePatientReservation(data) {
      debugger
      var dayNow = new Date();
      var myDate = new Date(data.DoctorSession.DateFrom);
      dayNow = new Date(dayNow);
      var date1 = dayNow.getTime();
      var date2 = myDate.getTime();
      if (date1 < date2) {
        this.$vs.notify({
          color: "warning",
          title: this.$t('CanNotCompleteSession'),
          text: this.$t("CanNotCompleteFutureSession"
          ),
        });
      }
      else {
        this.$store
          .dispatch("DoctorList/CompletePatientReservation", data.ID)
          .then((res) => {
            this.$vs.notify({
              title: this.$t("success"),
              text: res.data.Message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });

            this.getAppointmentSessions(false);
          })
          .catch((err) => {
            debugger;
            this.$vs.notify({
              title: this.$t("Error"),
              text: err.data.Message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          });
      }


    },
  },
  created() {
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
        localStorage.getItem("SaveLang") == null
        ? true
        : false;
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    this.getAppointmentSessions(false);
  },
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      >span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 10px;
          width: fit-content;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>